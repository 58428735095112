import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import programListStore from "@/store/modules/programList";
import { selectStar } from "@/services/userService/users-api";

import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import UIkit from "uikit";
import configureProgramConfirmation from '@/popupcomponents/configureprogramconfirmation/configureProgramConfirmation.vue';

@Component({
    components:{
        'configure-program-popup': configureProgramConfirmation
    }
})
export default class AccountAdminDashboard extends Vue{
    private objScreenText: ScreenText = new ScreenText();
    public programsDetail:Array<Object> = [];
    public emptyView: boolean = false;
    private userDataUpdated: any = {};
    private programName:string = "";
    private programID:string = "";
    public programId:number=0
    public accountId:number=0;
    public hoverStyleObj:any = {};
    public hover:boolean = false;
    public hoverText:string = '';
    public selectedProgramIndex:number = 0;
    programsList:any = [];

    @Prop()
    userDetails!: any;

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }
    mounted(){
        this.getProgramList();     
        this.setAccountData();   
    }

    beforeMount(){
        let dlObject = {
            "AccountName" : undefined,
            "AccountType" : undefined,
            "UserRole" : 0,
            "ProgramName" : undefined,
            "SiteName" : undefined
        }
        this.$emit('updateDataLayer', dlObject)
    }

    async setAccountData(){
        let accId = APP_UTILITIES.getAccountIdByCookies()
        let payload = {accountId : accId[0]}
        await programListStore.accountById(payload);
        /* istanbul ignore else */
        if (programListStore.accountDetails.status === APP_CONST.RESPONSE_200) {
            let accountDt = { ...programListStore.accountDetails.data };
            let dlObject = {'AccountName': accountDt.accountName ,'AccountType': accountDt.type, 'UserRole' : this.userDetails.roleId};
            APP_UTILITIES.setCookie('dlObject', JSON.stringify(dlObject), 1)
            this.$emit('updateDataLayer', dlObject)
        }
    }


    async getProgramList() {
        let storedAccountId:any = APP_UTILITIES.getCookie('user_role');
        const payload = APP_CONST.ACCOUNT_ADMIN_PROGRAM_LIST;
        payload.id = JSON.parse(storedAccountId)[0].accountId;
        await programListStore.getProgramList(payload);
        /* istanbul ignore else */
        if (programListStore.programsdata.status === APP_CONST.RESPONSE_200) {
            this.programsDetail  = [...programListStore.programsdata.data.results];
            this.programsDetail.forEach((item : any) => {
                item.hoverState = false;
                item.isStar = false;
            })
            this.emptyView = false;
        } 
        if (programListStore.programsdata.status === APP_CONST.RESPONSE_204) {
            this.emptyView = true;
        } 
    }

    navigateToProgramAdmin(data :any){
        let userRoles: any = APP_UTILITIES.getCookie("user_role");
        let program_id = data.programId;
        let program_name = data.programName ; 
        let userData = JSON.parse(userRoles);
        this.programName = program_name;
        this.programID = program_id;
        userData[0].programId =program_id;
        this.userDataUpdated = userData;
        let drilldown_key = {"actor":APP_CONST.ACCOUNT_ADMIN_ROLE_ID,"drill":APP_CONST.PROGRAM_ADMIN_ROLE_ID};
        /* istanbul ignore else */
        if(data.isConfigured) {
            userData[0].roleId =APP_CONST.PROGRAM_ADMIN_ROLE_ID;
            APP_UTILITIES.setCookie("user_role", JSON.stringify(userData),1) 
            APP_UTILITIES.setCookie("progName", program_name, 1);
            APP_UTILITIES.setCookie("programId", program_id, 1);
            APP_UTILITIES.setCookie("drilldown_key",JSON.stringify(drilldown_key),1);
            if(this.$route.name !== 'HomeProgAdmin') {
                if(navigator.userAgent.indexOf("Safari") != -1){
                this.$router.push({name:'HomeProgAdmin'}).finally(()=>{
                    window.location.reload()
                    
                })
            }else{
                this.$router.push({name:'HomeProgAdmin'})
            }
                this.$router.go(0);
            }
        } else {
            this.programNotConfigured(data, drilldown_key)
          }
        //   this.$emit('loadNav', {});

       }

    programNotConfigured(data : any, drilldown_key : any){
        this.programId=data.programId
        this.accountId=data.accountId
        APP_UTILITIES.setCookie("isProgramConfigured", JSON.stringify(false), 1);
        APP_UTILITIES.setCookie("account_admin_drilldown",JSON.stringify(drilldown_key),1 )
        if(UIkit.modal('#configure-program-confirmation')){
            UIkit.modal('#configure-program-confirmation').show();
        }
    }
    
    formatDate(date: string) {
        return APP_UTILITIES.formatDate(date);
      }

      sendinviteProgramAdmin(){
            document.cookie = "editProgramId=" +  this.programId;
            APP_UTILITIES.setCookie("accountId",JSON.stringify(this.accountId), 1);
            this.$router.push({name:'InviteProgram'})
      }

    popupDisplay = () => {
        let popupNewPopDisplay = <HTMLInputElement>document.getElementById('configure-program-confirmation');
        if (popupNewPopDisplay && (popupNewPopDisplay.style.display !== 'none')) {
            popupNewPopDisplay.style.display = 'none';
        }
        let popupDisplay = <any>document.getElementsByTagName('html');
        popupDisplay[0].classList.remove('uk-modal-page')
    }
    
    closeModal(){
        /* istanbul ignore else */
        if( UIkit.modal('#configure-program-confirmation')){
            UIkit.modal('#configure-program-confirmation').hide();
        }
        let popupPopDisplay = <HTMLInputElement>document.getElementById('configure-program-confirmation');
        /* istanbul ignore else */
        if (popupPopDisplay) {
            popupPopDisplay.style.display = 'none';
            setTimeout(this.popupDisplay, 1000);
        }
    }

    navigateToConfigure(){
        APP_UTILITIES.setCookie("progName", this.programName, 1); 
        APP_UTILITIES.setCookie("accountId", this.userDataUpdated[0].accountId, 1); 
        APP_UTILITIES.setCookie("programId", this.programID, 1);
        APP_UTILITIES.setCookie("user_role", JSON.stringify(this.userDataUpdated),1) 
        this.$router.push('/configureprogram');
    }

    showTooltip(val : string, $event: any, id:string, isMobileView: string){
        this.hover = true;
        this.hoverText = val;
        const boundBox = $event && $event.target.getBoundingClientRect();
        const coordX = boundBox.left;
        const coordY = boundBox.top;
        this.hoverStyleObj = {
            top:(coordY + 100).toString() + "px",
            left:(coordX + 50).toString() + "px",
            width: "fit-content;"
        }
    }

    hideTooltip(){
        this.hover = false;
        this.hoverText = '';
        this.hoverStyleObj= {};
    }

    hoverInNOut(index : number, hoverIn?:boolean) {
        let programData = JSON.parse(JSON.stringify(this.programsDetail));
        programData.forEach((item : any, key : number) => {
            /* istanbul ignore else */
            if(key === index){
                    item.hoverState = (hoverIn && item.programName.length > 25) ? true : false;
            }
        })
        this.programsDetail = [...programData];
    }

    callHover(index : number){
        this.hoverInNOut(index, true);
    }

    callHoverOut(index : number){
        this.hoverInNOut(index);
    }

    toggleProgramTab(index:number){
        if(this.selectedProgramIndex == index) {
            this.selectedProgramIndex = -1;
        } else {
            this.selectedProgramIndex = index;
        }
    }

    sortByProgramName(property: any) {
        this.programsList.sort(this.sortByProperty('programName', 'asc'))

        this.programsList.forEach((program:any) => {
            return program.siteStarInfos.sort(this.sortByProperty('siteName', 'asc'))
        });
    }

    sortByStar(programId:number, siteId:number) {
        if(programId) {
            let { id} = APP_UTILITIES.coreids();
            let payload = {
                siteId: siteId,
                programId: programId,
                userId: id
            };

            selectStar(payload).then((response) => {
                this.programsList = response.data;
              }, (error) => {
                  console.log(error);
              });  
        }
        this.programsList.sort(this.sortByProperty('programName', 'asc'))
        this.programsList.forEach((program:any) => {
            return program.siteStarInfos.sort(this.sortByProperty('siteName', 'asc'))
        });
        
        this.programsList.sort(this.sortByProperty('isStar', 'desc'));
        this.programsList.forEach((program:any) => {
            return program.siteStarInfos.sort(this.sortByProperty('isStar', 'desc'))
        });
    }

    sortByProperty(property: any, order:string) {
        return function (a: any, b: any) {
            if(order === APP_CONST.ORDER_ASC_TEXT) {
                if (a[property] > b[property])
                    return 1;
                else if (a[property] < b[property])
                    return -1;
            } else {
                if (a[property] < b[property])
                    return 1;
                else if (a[property] > b[property])
                    return -1;
            }            

            return 0;
        }
    }
}
