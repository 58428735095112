










































































import AccountAdminDashboard from "@/components/accountadmindashboard/AccountAdminDashboard";
export default AccountAdminDashboard;
