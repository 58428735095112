import { render, staticRenderFns } from "./AccountAdminDashboard.vue?vue&type=template&id=16fb3b7c&scoped=true&"
import script from "./AccountAdminDashboard.vue?vue&type=script&lang=ts&"
export * from "./AccountAdminDashboard.vue?vue&type=script&lang=ts&"
import style0 from "./AccountAdminDashboard.less?vue&type=style&index=0&id=16fb3b7c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16fb3b7c",
  null
  
)

export default component.exports